import { Button, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { languages } from "../Shared/languages";
import Axios from "axios";
import { devices_UAs } from "../Shared/devices_UAs";
import { Firebase } from "../Config/firebase.config";
import { useHistory } from "react-router";
import SnackbarToast from "../Utils/SnackbarToast";
import LoadingSpinner from "../Utils/LoadingSpinner";
import publicIp from "public-ip";

const checkboxLabel = ["Region", "City", "Country", "Postal Code", "All"];

const useStyles = makeStyles(() => ({
  popper: {
    height: "80vh",
    "& .MuiPaper-root": {
      maxHeight: "100%",
      "& .MuiAutocomplete-listbox": {
        maxHeight: "80vh",
      },
    },
  },
}));

const stringArray = [
  "-",
  "-",
  "-",
  "-",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "-",
  "-",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "M",
  "T",
  "L",
];

export default function Home() {
  const [language, setLanguage] = useState("");
  const [query, setQuery] = useState("");
  const [hl, setHl] = useState("");
  const [countryDomain, setCountryDomain] = useState("");
  const [locationFilters, setLocationFilters] = useState(
    Array.from({ length: checkboxLabel.length }, () => true)
  );
  const [searchURL, setSearchURL] = useState("");
  const [canonicalName, setCanonicalName] = useState("");
  const [geoTargets, setAllGeoTargets] = useState([]);
  const [device, setDevice] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [string, setString] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageForSnackbar, setMessageForSnackbar] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loggedinUserEmail, setLoggedinUserEmail] = useState("");

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);
    Firebase.auth().onAuthStateChanged((user) => {
      if (!user) history.push("/login");
      else {
        setLoggedinUserEmail(user.email);
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    const geoTargetSelected = geoTargets.filter(
      (geoTarget) => geoTarget["canonical_name"] === canonicalName
    )[0];

    if (language) {
      const languageSelected = languages.filter(
        (lang) => lang["language name"] === language
      )[0];
      const hlForLanguage = languageSelected["language code"];
      setHl(hlForLanguage);
    }

    if (geoTargetSelected) {
      setCountryDomain(geoTargetSelected["domain"]);

      setString(stringArray[geoTargetSelected["canonical_name"].length]);
    }

    if (device) {
      const deviceSelected = devices_UAs.filter(
        (device_UA) => device_UA.Device === device
      )[0];
      setUserAgent(deviceSelected.UA);
    }

    if (!!!language) {
      setHl("");
      setLanguage("");
    }

    if (!!!geoTargetSelected) {
      setCanonicalName("");
      setCountryDomain("");
      setString("");
    }

    if (!!!device) {
      setDevice("");
      setUserAgent("");
    }
  }, [canonicalName, language, device]);

  async function searchResultHandler(e) {
    e.preventDefault();

    if (!query.trim().length) {
      alert("Please enter query to proceed");
      return;
    } else {
      let ipAddress = "";

      try {
        ipAddress = await publicIp.v4({
          fallbackUrls: ["https://ifconfig.co/ip"],
        });
      } catch (err) {
        alert("Something went wrong, while fetching IP...");
        return;
      }

      const base64EncodeOfCanonicalName = btoa(canonicalName);
      const uule = `w+CAIQICI${string}${base64EncodeOfCanonicalName}`;

      setSearchURL(
        `https://www.${
          countryDomain ? countryDomain : "google.com/"
        }search?igu=1&q=${query}&hl=${hl}&uule=${uule}&adtest-useragent=${userAgent}&ip=${ipAddress}`
      );

      Axios.get(`https://us-central1-dhappa-j.cloudfunctions.net/search`, {
        params: {
          countryDomain: countryDomain ? countryDomain : "google.com/",
          igu: 1,
          q: query,
          hl,
          uule,
          "adtest-useragent": userAgent,
          ip: ipAddress,
          user: loggedinUserEmail,
        },
      });
    }
  }

  const canonicalNameChangeHandler = (e) => {
    if (e.target.value.length > 2) {
      Axios.get("https://retag-data.aldebaranmedia.com/geo-targets/search", {
        params: {
          keyword: e.target.value,
          targets: locationFilters
            .slice(0, -1)
            .map((e, i) => checkboxLabel[i])
            .filter((e, i) => locationFilters[i])
            .join(),
        },
      })

        .then((res) => {
          if (res.data?.status === 1 && res.data?.data.length > 0)
            setAllGeoTargets(res.data?.data);

          if (res.data?.status === 1 && !res.data?.data.length)
            setAllGeoTargets([]);
        })

        .catch((err) => {
          // alert("Oops..something went wrong!");
          console.log("Oops..something went wrong!");
          return;
        });
    }
  };

  const handleCheckbox = (index) => {
    if (index === checkboxLabel.length - 1) {
      setLocationFilters(
        Array.from(
          { length: checkboxLabel.length },
          () => !locationFilters[index]
        )
      );
    } else {
      const temp = [...locationFilters];

      if (temp.every((v) => v == true)) {
        temp[checkboxLabel.length - 1] = false;
      } else if (temp[checkboxLabel.length - 1]) {
        temp[checkboxLabel.length - 1] = !temp[checkboxLabel.length - 1];
      } else if (
        temp
          .filter(
            (val, key) => key !== index && key !== checkboxLabel.length - 1
          )
          .every((v) => v === true)
      ) {
        temp[checkboxLabel.length - 1] = true;
      }
      temp[index] = !temp[index];
      setLocationFilters(temp);
    }
  };

  const onLogoutUserClick = () => {
    setIsLoading(true);
    Firebase.auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        history.push("/login");
      })
      .catch((error) => {
        // An error happened.
        setOpenSnackbar(true);
        setSnackbarType("error");
        setMessageForSnackbar(error.message);
        setIsLoading(false);
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarType("");
    setMessageForSnackbar("");
  };

  return (
    <React.Fragment>
      <LoadingSpinner isLoading={isLoading} />

      <SnackbarToast
        handleSnackbarClose={handleSnackbarClose}
        openSnackbar={openSnackbar}
        snackbarType={snackbarType}
        message={messageForSnackbar}
      />

      <div className="ad-spying-tool">
        <form onSubmit={searchResultHandler}>
          <div className="row app-bar">
            <div className="col-lg-6 col-md-6 mt-2 mb-2 canonical-name-list d-flex flex-row-reverse">
              <div className="d-flex flex-column ml-2">
                {checkboxLabel.map((label, index) => (
                  <div className="d-flex  align-items-center" key={index}>
                    <input
                      type="checkbox"
                      name="region"
                      checked={locationFilters[index]}
                      onChange={() => handleCheckbox(index)}
                    />
                    <label
                      htmlFor="region"
                      className="mb-0 ml-1"
                      style={{ fontSize: "10px" }}
                    >
                      {label}
                    </label>
                  </div>
                ))}
              </div>
              <Autocomplete
                id="canonical-name-list"
                style={{ width: "100%" }}
                options={geoTargets}
                classes={{ popper: classes.popper }}
                onChange={(e) => {
                  if (!!e.target.innerText) {
                    setCanonicalName(e.target.innerText.split(" | ")[0]);
                    document.getElementById("language-list").focus();
                  } else {
                    setCanonicalName("");
                    setAllGeoTargets([]);
                  }
                }}
                getOptionLabel={(geoTargets) =>
                  geoTargets["canonical_name"] +
                  " | " +
                  geoTargets["target_type"] +
                  " | " +
                  geoTargets["criteria_id"]
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => {
                      canonicalNameChangeHandler(e);
                    }}
                    label="Enter Location"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="col-lg-2 col-md-2 mt-2 mb-2 language-list">
              <Autocomplete
                id="language-list"
                options={languages}
                getOptionLabel={(languages) => languages["language name"]}
                onChange={(e) => {
                  if (!!e.target.innerText) {
                    setLanguage(e.target.innerText);
                    document.getElementById("search-field").focus();
                  } else {
                    setLanguage("");
                    setHl("");
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Language" variant="outlined" />
                )}
              />
            </div>
            {/* <div className="col-lg-2 col-md-4 mt-2 mb-2 device-list">
                        <Autocomplete
                        id="device-list"
                        options={devices_UAs}
                        onChange={(e)=> {
                            if(!!e.target.innerText) setDevice(e.target.innerText)
                            else {
                                setDevice("")
                                setUserAgent("")
                            }
                        }}
                        getOptionLabel={(devices_UAs) => devices_UAs["Device"]}
                        renderInput={(params) => <TextField {...params} label="Device" variant="outlined" />}
                        />
                    </div> */}
            <div className="col-lg-2 col-md-2 mt-2 mb-2 search-field">
              <TextField
                id="search-field"
                fullWidth
                placeholder="What's on your mind?"
                onChange={(e) => setQuery(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="col-lg-2 col-md-2 mt-2 mb-2 search-button text-left d-flex justify-content-around">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  width: 100,
                  height: 50,
                  marginRight: "3px",
                  marginLeft: "-15px",
                }}
              >
                {" "}
                Search
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={onLogoutUserClick}
                style={{ width: 80, height: 50 }}
              >
                {" "}
                Logout
              </Button>
            </div>
          </div>
        </form>

        <div className="search-result row">
          <iframe
            title="Google search results"
            style={{ border: "none" }}
            id="search-result"
            width="100%"
            src={
              searchURL.trim().length === 0
                ? `https://www.google.co.in/search?igu=1`
                : searchURL
            }
          ></iframe>
        </div>
      </div>
    </React.Fragment>
  );
}
