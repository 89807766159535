export const languages = 
        [{
            "language code": "af",
            "language name": "Afrikaans"
        },
        {
            "language code": "ak",
            "language name": "Akan"
        },
        {
            "language code": "sq",
            "language name": "Albanian"
        },
        {
            "language code": "am",
            "language name": "Amharic"
        },
        {
            "language code": "ar",
            "language name": "Arabic"
        },
        {
            "language code": "hy",
            "language name": "Armenian"
        },
        {
            "language code": "as",
            "language name": "Assamese"
        },
        {
            "language code": "az",
            "language name": "Azerbaijani"
        },
        {
            "language code": "bm",
            "language name": "Bambara"
        },
        {
            "language code": "eu",
            "language name": "Basque"
        },
        {
            "language code": "be",
            "language name": "Belarusian"
        },
        {
            "language code": "bn",
            "language name": "Bengali"
        },
        {
            "language code": "bs",
            "language name": "Bosnian"
        },
        {
            "language code": "br",
            "language name": "Breton"
        },
        {
            "language code": "bg",
            "language name": "Bulgarian"
        },
        {
            "language code": "my",
            "language name": "Burmese"
        },
        {
            "language code": "ca",
            "language name": "Catalan"
        },
        {
            "language code": "zh",
            "language name": "Chinese"
        },
        {
            "language code": "kw",
            "language name": "Cornish"
        },
        {
            "language code": "hr",
            "language name": "Croatian"
        },
        {
            "language code": "cs",
            "language name": "Czech"
        },
        {
            "language code": "da",
            "language name": "Danish"
        },
        {
            "language code": "nl",
            "language name": "Dutch"
        },
        {
            "language code": "dz",
            "language name": "Dzongkha"
        },
        {
            "language code": "en",
            "language name": "English"
        },
        {
            "language code": "eo",
            "language name": "Esperanto"
        },
        {
            "language code": "et",
            "language name": "Estonian"
        },
        {
            "language code": "ee",
            "language name": "Ewe"
        },
        {
            "language code": "fo",
            "language name": "Faroese"
        },
        {
            "language code": "fi",
            "language name": "Finnish"
        },
        {
            "language code": "fr",
            "language name": "French"
        },
        {
            "language code": "ff",
            "language name": "Fulah"
        },
        {
            "language code": "gl",
            "language name": "Galician"
        },
        {
            "language code": "lg",
            "language name": "Ganda"
        },
        {
            "language code": "ka",
            "language name": "Georgian"
        },
        {
            "language code": "de",
            "language name": "German"
        },
        {
            "language code": "el",
            "language name": "Greek"
        },
        {
            "language code": "gu",
            "language name": "Gujarati"
        },
        {
            "language code": "ha",
            "language name": "Hausa"
        },
        {
            "language code": "he",
            "language name": "Hebrew"
        },
        {
            "language code": "hi",
            "language name": "Hindi"
        },
        {
            "language code": "hu",
            "language name": "Hungarian"
        },
        {
            "language code": "is",
            "language name": "Icelandic"
        },
        {
            "language code": "ig",
            "language name": "Igbo"
        },
        {
            "language code": "id",
            "language name": "Indonesian"
        },
        {
            "language code": "ga",
            "language name": "Irish"
        },
        {
            "language code": "it",
            "language name": "Italian"
        },
        {
            "language code": "ja",
            "language name": "Japanese"
        },
        {
            "language code": "kl",
            "language name": "Kalaallisut"
        },
        {
            "language code": "kn",
            "language name": "Kannada"
        },
        {
            "language code": "ks",
            "language name": "Kashmiri"
        },
        {
            "language code": "kk",
            "language name": "Kazakh"
        },
        {
            "language code": "km",
            "language name": "Khmer"
        },
        {
            "language code": "ki",
            "language name": "Kikuyu"
        },
        {
            "language code": "rw",
            "language name": "Kinyarwanda"
        },
        {
            "language code": "ko",
            "language name": "Korean"
        },
        {
            "language code": "ky",
            "language name": "Kyrgyz"
        },
        {
            "language code": "lo",
            "language name": "Lao"
        },
        {
            "language code": "lv",
            "language name": "Latvian"
        },
        {
            "language code": "ln",
            "language name": "Lingala"
        },
        {
            "language code": "lt",
            "language name": "Lithuanian"
        },
        {
            "language code": "lu",
            "language name": "Luba-Katanga"
        },
        {
            "language code": "lb",
            "language name": "Luxembourgish"
        },
        {
            "language code": "mk",
            "language name": "Macedonian"
        },
        {
            "language code": "mg",
            "language name": "Malagasy"
        },
        {
            "language code": "ms",
            "language name": "Malay"
        },
        {
            "language code": "ml",
            "language name": "Malayalam"
        },
        {
            "language code": "mt",
            "language name": "Maltese"
        },
        {
            "language code": "gv",
            "language name": "Manx"
        },
        {
            "language code": "mr",
            "language name": "Marathi"
        },
        {
            "language code": "mn",
            "language name": "Mongolian"
        },
        {
            "language code": "ne",
            "language name": "Nepali"
        },
        {
            "language code": "nd",
            "language name": "North Ndebele"
        },
        {
            "language code": "se",
            "language name": "Northern Sami"
        },
        {
            "language code": "no",
            "language name": "Norwegian"
        },
        {
            "language code": "nb",
            "language name": "Norwegian Bokmål"
        },
        {
            "language code": "nn",
            "language name": "Norwegian Nynorsk"
        },
        {
            "language code": "or",
            "language name": "Oriya"
        },
        {
            "language code": "om",
            "language name": "Oromo"
        },
        {
            "language code": "os",
            "language name": "Ossetic"
        },
        {
            "language code": "ps",
            "language name": "Pashto"
        },
        {
            "language code": "fa",
            "language name": "Persian"
        },
        {
            "language code": "pl",
            "language name": "Polish"
        },
        {
            "language code": "pt",
            "language name": "Portuguese"
        },
        {
            "language code": "pa",
            "language name": "Punjabi"
        },
        {
            "language code": "qu",
            "language name": "Quechua"
        },
        {
            "language code": "ro",
            "language name": "Romanian"
        },
        {
            "language code": "rm",
            "language name": "Romansh"
        },
        {
            "language code": "rn",
            "language name": "Rundi"
        },
        {
            "language code": "ru",
            "language name": "Russian"
        },
        {
            "language code": "sg",
            "language name": "Sango"
        },
        {
            "language code": "gd",
            "language name": "Scottish Gaelic"
        },
        {
            "language code": "sr",
            "language name": "Serbian"
        },
        {
            "language code": "sh",
            "language name": "Serbo-Croatian"
        },
        {
            "language code": "sn",
            "language name": "Shona"
        },
        {
            "language code": "ii",
            "language name": "Sichuan Yi"
        },
        {
            "language code": "si",
            "language name": "Sinhala"
        },
        {
            "language code": "sk",
            "language name": "Slovak"
        },
        {
            "language code": "sl",
            "language name": "Slovenian"
        },
        {
            "language code": "so",
            "language name": "Somali"
        },
        {
            "language code": "es",
            "language name": "Spanish"
        },
        {
            "language code": "sw",
            "language name": "Swahili"
        },
        {
            "language code": "sv",
            "language name": "Swedish"
        },
        {
            "language code": "tl",
            "language name": "Tagalog"
        },
        {
            "language code": "ta",
            "language name": "Tamil"
        },
        {
            "language code": "te",
            "language name": "Telugu"
        },
        {
            "language code": "th",
            "language name": "Thai"
        },
        {
            "language code": "bo",
            "language name": "Tibetan"
        },
        {
            "language code": "ti",
            "language name": "Tigrinya"
        },
        {
            "language code": "to",
            "language name": "Tongan"
        },
        {
            "language code": "tr",
            "language name": "Turkish"
        },
        {
            "language code": "uk",
            "language name": "Ukrainian"
        },
        {
            "language code": "ur",
            "language name": "Urdu"
        },
        {
            "language code": "ug",
            "language name": "Uyghur"
        },
        {
            "language code": "uz",
            "language name": "Uzbek"
        },
        {
            "language code": "vi",
            "language name": "Vietnamese"
        },
        {
            "language code": "cy",
            "language name": "Welsh"
        },
        {
            "language code": "fy",
            "language name": "Western Frisian"
        },
        {
            "language code": "yi",
            "language name": "Yiddish"
        },
        {
            "language code": "yo",
            "language name": "Yoruba"
        },
        {
            "language code": "zu",
            "language name": "Zulu"
        }
    ]
