import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 11111111111,
        color: '#fff',
    }

}));

function LoadingSpinner({isLoading}) {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        
    )
}

export default LoadingSpinner
