import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import LoginPageLogo from "../Assets/img/login_page_logo.svg";
import LoadingSpinner from "../Utils/LoadingSpinner";
import SnackbarToast from '../Utils/SnackbarToast';
import { Firebase } from '../Config/firebase.config';

const Login = ({setAuthState}) => {
    
    const [state, setState] = useState({
        email:'',
        password:''
    })

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageForSnackbar, setMessageForSnackbar] = useState("");
    const [snackbarType, setSnackbarType] = useState("");
    const [errors, setErrors] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {

        setIsLoading(true);
        Firebase.auth().onAuthStateChanged(user => {

            if (user) history.push('/');
            else setIsLoading(false);

        })

    }, []);

    const isFormValid = () => {

      let isValid = true;
      const errorData = {};
      const regexForEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(!regexForEmail.test(String(state.email).toLowerCase())) {
        errorData.email = "Email is invalid";
        isValid = false;
      }

      if(state.password === '' || state.password.trim() === '' || state.password.trim().length < 6){
        errorData.password = 'Password is required and must be atleast 6 characters long'
        isValid = false;
      }

      setErrors(errorData);
      return isValid;

    }

    const handleChange=({target})=>{

        setState({...state,[target.name]:target.value})
    }

    const handleLogin = async (e) => {

      e.preventDefault();
      const isValid = isFormValid();
      if(isValid){

        setIsLoading(true);
        const userCredential = await Firebase.auth().signInWithEmailAndPassword(state.email, state.password).catch((error) => {
            
            setOpenSnackbar(true);
            setSnackbarType("error");
            setMessageForSnackbar(error.message);     
            setIsLoading(false);
        });

        if (userCredential && userCredential.user) history.push('/home');
        
      }
    }

    const handleSnackbarClose = () => {

       setOpenSnackbar(false);
       setSnackbarType("");
       setMessageForSnackbar("");
    }
    
    return (
        
        <div class="form-body" style={{backgroundColor: "#0092FF"}}>
      
        <LoadingSpinner isLoading={isLoading} />

        <SnackbarToast
            handleSnackbarClose={handleSnackbarClose}
            openSnackbar={openSnackbar}
            snackbarType={snackbarType}
            message={messageForSnackbar}
        />       

        <div class="row">
            <div class="img-holder" style={{backgroundColor:"#5CBBFF"}}>
            <div class="bg"></div>
            <div class="info-holder">
                <img src={LoginPageLogo} alt="" />
            </div>
            </div>
            <div class="form-holder">
            <div class="form-content"  style={{backgroundColor: "#0092FF"}}>
                <div class="form-items">
                <div class="page-links">
                    <Link class="active" style={{fontSize: 25}}>
                    Dhappa J
                    </Link>
                </div>
                <form>
                    <label htmlFor="" style={{width:'100%'}}>
                    <input
                    class="form-control input-field"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required
                    value={state.email}
                    onChange={handleChange}
                    style={{marginBottom:5}}
                    />
                    <span style={{color:"darkred", fontWeight: 500, fontSize: 12, display:'block',minHeight:20}}>{errors && errors.email}</span>
                    </label>
                    <label htmlFor="" style={{width:'100%'}}>
                    <input
                    class="form-control input-field"
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                    value={state.password}
                    onChange={handleChange}
                    style={{marginBottom:5}}
                    />
                    <span style={{color:"darkred", fontWeight: 500, fontSize: 12, display:'block',minHeight:20}}>{errors && errors.password}</span>
                    </label>
                    <div class="form-button">
                    <button id="submit" type="submit" class="ibtn" style={{color:"#263238"}} onClick={handleLogin}>
                        Login
                    </button>{" "}
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
        </div>
  );
};

export default Login;
